<template>
  <div class="home-container">
    <div class="header-box">
      <img src="@/assets/avatar.webp" />
      <span>{{ mobile }}</span>
    </div>
    <div class="float-service" @click="handleService">
      <div class="service-icon">
        <van-icon name="service-o" />
      </div>
      <span class="service-text">在线客服</span>
    </div>
    <div class="top-box"></div>
    <div class="product-list">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="item" v-for="(product, index) in productList" :key="index" v-on:click="
        go2Apply(
          product.productCode,
          product.productName,
          product.productUrl
        )
        ">
          <div class="left">
            <img class="order" :src="obj[index + 1]" />
            <img class="logo" :src="product.logoUrl" />
          </div>
          <div class="center">
            <div>{{ product.productName }}</div>
            <div class="num">{{ product.interestRate }}%日利率</div>
          </div>
          <div class="right">
            <span>{{ product.applyNum }}人申请</span>
            <img class="order" src="@/assets/star.webp" v-if="index <= 2" />
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getProductList, recordClick } from '@/api/user'

export default {
  data() {
    return {
      mobile: localStorage.getItem('username'),
      productList: [],
      obj: {
        1: require('@/assets/o1.webp'),
        2: require('@/assets/o2.webp'),
        3: require('@/assets/o3.webp')
      },
      isLoading: false
    }
  },
  props: {
    source: {
      type: String,
      default: localStorage.getItem('source')
    }
  },
  methods: {
    async loadProduct() {
      const res = await getProductList()
      this.productList = res.data
    },
    showIcon(index) {
      return `@/assets/o${index + 1}.webp`
    },
    async onRefresh() {
      this.isLoading = true
      const res = await getProductList()
      this.productList = res.data
      this.isLoading = false
    },
    async go2Apply(code, name, url) {
      await recordClick({ productCode: code })
      window.location.href = url
    },
    handleService() {
      const username = localStorage.getItem('username')
      window.location.href = `https://ax.aohepu.cn/chat/mobile?noCanClose=1&token=bcc631c1bf7db5c6f3e46b1f12e74705&nickName=${username}&phone=${username}`
    }
  },
  mounted() {
    const source = this.$route.params.source || 'unknow'
    localStorage.setItem('source', source)
    this.loadProduct()
  }
}
</script>

<style scoped lang="less">
.home-container {
  height: calc(100vh - 100px);
  background: linear-gradient(180deg, #517cdd 0%, #1555c0 100%);
  overflow: hidden;
  position: relative;

  .header-box {
    // width: 690px;
    width: 90%;
    height: 212px;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0px 16px 16px 1px rgba(11, 39, 40, 0.08);
    border-radius: 25px 25px 25px 25px;
    transform: translate(5%, 50px);
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 120px;
      margin-left: 30px;
      margin-right: 40px;
    }

    span {
      font-size: 30px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 1px;
    }
  }

  .top-box {
    background-image: url('~@/assets/top-bg.webp');
    height: 329px;
    background-size: cover;
    margin-top: 35px;
  }

  .product-list {
    width: 90%;
    height: calc(100vh - 700px);
    background: rgba(236, 245, 255, 0.99);
    border-radius: 4px;
    transform: translate(5%);
    margin-bottom: 20px;
    overflow: hidden;

    /deep/ .van-pull-refresh {
      height: 100%;
      overflow-y: auto;
    }

    .item {
      height: 170px;
      padding: 25px 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #cccccc;
      display: flex;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
        margin-bottom: 20px;
      }

      .left {
        display: flex;
        align-items: center;

        .order {
          width: 60px;
          margin-right: 20px;
        }

        .logo {
          width: 88px;
          border-radius: 12px;
        }
      }

      .center {
        font-size: 26px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .num {
          color: #999;
        }
      }

      .right {
        display: flex;
        align-items: center;

        span {
          font-size: 28px;
          //   font-family: ;
          font-weight: normal;
          color: #ffae00;
        }

        img {
          width: 60px;
        }
      }
    }
  }

  .float-service {
    position: fixed;
    right: 20px;
    top: 40px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background: linear-gradient(135deg, #1989fa, #0056b3);
    border-radius: 24px;
    box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    transition: transform 0.3s ease;

    &:active {
      transform: scale(0.95);
    }

    .service-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;

      .van-icon {
        font-size: 20px;
        color: #fff;
      }
    }

    .service-text {
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
    }

    // 添加呼吸灯效果
    animation: breath 2s ease-in-out infinite;
  }

  @keyframes breath {
    0% {
      transform: scale(1);
      box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    }

    50% {
      transform: scale(1.05);
      box-shadow: 0 4px 16px rgba(25, 137, 250, 0.4);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    }
  }
}

/deep/ .van-pull-refresh__track {
  min-height: 100% !important;
}
</style>
